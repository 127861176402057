/* eslint-disable eqeqeq */
import "../dataTable/DataTable.css";
import ENV from "../../constants/Environment";
import { PersonDetails } from "../personDetails/PersonDetails";
import {
  CustomTableCell,
  CustomTableRow,
  FontWeight,
} from "../../globalStyles/emotion";
import DashTimeFormat from "../time/DashTimeFormat";
import { RideDetailsBadges, RideMetaBadges } from "../badges/RideBadges";
import FirstAvailableTooltip from "../firstAvailable/FirstAvailableTooltip";
import { dateTime } from "@onwardcare/core/utils/date-format";

const { onwardApiUrl } = ENV;

const TableRowData = ({ data, rowId, setRowId, showCreatedTime }) => {
  const handleToggleSidePanel = (event, id) => {
    setRowId(id);
  };

  return (
    <>
      {data.map(
        ({
          id,
          transportTypeId,
          rideType,
          hasStairs,
          isBariatric,
          isLongDistance,
          availableAt,
          status,
          firstAvailable,
          requestedStartTime,
          requestedEndTime,
          riderDisplayName,
          riderEmail,
          riderPhone,
          riderId,
        }) => (
          <CustomTableRow
            style={{
              background: rowId == id ? "rgba(0,128,128,0.2)" : "white",
              width: "700px",
              zIndex: -9999,
            }}
            key={id}
            onClick={(event) => handleToggleSidePanel(event, id)}
            value={id}
          >
            <CustomTableCell
              align="left"
              style={{
                minWidth: "160px",
                maxWidth: "160px",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <RideDetailsBadges
                  id={id}
                  rideType={rideType}
                  transportTypeId={transportTypeId}
                />
                <RideMetaBadges
                  id={id}
                  hasStairs={hasStairs}
                  isBariatric={isBariatric}
                  isLongDistance={isLongDistance}
                />
              </div>
            </CustomTableCell>

            <CustomTableCell
              style={{
                minWidth: showCreatedTime ? "200px" : "320px",
                maxWidth: showCreatedTime ? "200px" : "320x",
                zIndex: -9999,
                padding: "10px",
              }}
              colSpan={showCreatedTime ? null : "2"}
            >
              <PersonDetails
                persons={[
                  {
                    nameRow: "Rider",
                    phoneRow: "Phone",
                    emailRow: "Email",

                    personName: riderDisplayName,
                    personEmail: riderEmail,
                    personPhone: riderPhone,
                    personId: riderId,
                    personLink: `${onwardApiUrl}/admin/riders/${riderId}`,
                  },
                ]}
                MaxWordSize={20}
                shouldShowActions
              />
            </CustomTableCell>

            {showCreatedTime && (
              <CustomTableCell
                align="left"
                style={{
                  minWidth: "100px",
                  maxWidth: "100px",
                  padding: "10px",
                }}
              >
                <FontWeight>{dateTime(availableAt)}</FontWeight>
              </CustomTableCell>
            )}

            <CustomTableCell
              align="left"
              style={{
                minWidth: "100px",
                maxWidth: "100px",
                padding: "10px",
              }}
            >
              {firstAvailable && status.toLowerCase() === "pending" ? (
                <FontWeight>First Available</FontWeight>
              ) : (
                <DashTimeFormat
                  requestedStartTime={requestedStartTime}
                  requestedEndTime={requestedEndTime}
                />
              )}
              {firstAvailable && <FirstAvailableTooltip status={status} />}
            </CustomTableCell>
          </CustomTableRow>
        )
      )}
    </>
  );
};

export default TableRowData;
