/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Box } from "@mui/system";
import { PurpleButton, SecondaryButton } from "../../../globalStyles/emotion";

import Duration from "../../../components/duration/Duration";

import ClaimRideCard from "../../../components/claimRideCard/ClaimRideCard";
import BestTimeBidModal from "../../../components/bestTimeBidModal/BestTimeBidModal";

import DashTimeFormat from "../../../components/time/DashTimeFormat";

import DialogMessage from "../../../components/dialogMessage/DialogMessage";
import SidePanelDetails from "../../../components/sidePanelDetails/SidePanelDetails";
import {
  getDriversDetails,
  getBestTimeOptions,
  getBestTimeBids,
} from "../../../axiosHandlers/PendingDataFetchers";

const PendingViewSidePanel = ({
  rowId,
  pendingData,
  transportPartnerData,
  setReloadData,
  setAlertColor,
  setClaimRideMessage,
  setSubmitBidMessage,
}) => {
  const savedPartnerId = +localStorage.getItem("partnerId") || -1; // This is a hack

  const [open, setOpen] = React.useState(false);
  const [toggleClaimId, setToggleClaimId] = React.useState(null);
  const [toggleBestTimeModal, setToggleBestTimeModal] = React.useState(null);
  const [driverFirstName, setDriverFirstName] = React.useState("");
  const [driverLastName, setDriverLastName] = React.useState("");
  const [partnerId, setPartnerId] = React.useState(savedPartnerId);
  const [driverData, setDriverData] = React.useState([]);
  const [driverNotes, setDriverNotes] = React.useState(null);
  const [disable, setDisable] = React.useState(true);
  const [driver, setDriver] = React.useState("");
  const [selectedBestTime, setSelectedBestTime] = React.useState("");
  const [bestTimeOptions, setBestTimeOptions] = React.useState("");
  const [error, setError] = useState(null);
  const [bestTimeBids, setBestTimeBids] = useState([]);
  const stanardErrorMSG = "This ride is no longer available";

  const mainDivStyles = {
    background: "rgba(0,128,128,0.2)",
    paddingLeft: "25px",
    paddingRight: "25px",
    height: "70px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopRightRadius: "25px",
  };

  const cleanDriverFieldStates = () => {
    setToggleClaimId(null);
    setPartnerId(savedPartnerId);
    setDriver("");
    setDriverFirstName("");
    setDisable(true);
  };

  const closeBidModal = () => {
    cleanDriverFieldStates();
    setToggleBestTimeModal(false);
    setSelectedBestTime("");
  };

  const fetchDriverData = async (transportTypeId, partnerId) => {
    const { data } = await getDriversDetails({ transportTypeId, partnerId });
    setDriverData(data);
    setDriver("");
  };

  const fetchBestTimeBids = async (rideId) => {
    const {
      data: { message },
    } = await getBestTimeBids(rideId);
    setBestTimeBids(message.bestTimeBids);
  };

  const handleFetchDriverData = async (event, transportTypeId) => {
    setToggleClaimId(event.target.value);
    await fetchDriverData(transportTypeId, partnerId);
  };

  const handleFetchBestTimeOptions = async (
    transportTypeId,
    partnerId,
    rideId
  ) => {
    setToggleBestTimeModal(true);
    await fetchDriverData(transportTypeId, partnerId);
    const { data } = await getBestTimeOptions(rideId);
    if (isEmpty(data.bestTimeOptions)) {
      setError(stanardErrorMSG);
    } else {
      setError(null);
    }
    setBestTimeOptions(data.bestTimeOptions);
  };

  return (
    <div style={{ flex: 1 }}>
      {pendingData.map(
        ({
          custodianDisplayName,
          vehicleSummary,
          custodianPhone,
          custodianEmail,
          driverId,
          driverDisplayName,
          driverPhone,
          driverEmail,
          startLocationAddress,
          endLocationAddress,
          isBariatric,
          riderDisplayName,
          riderPhone,
          estimatedPayout,
          bonus,
          estimatedTotal,
          startLocationNotes,
          endLocationNotes,
          startLocationName,
          endLocationName,
          id,
          hasStairs,
          rideType,
          transportTypeId,
          moveToRide,
          riderEmail,
          rideNotes,
          riderNumber,
          riderNotes,
          riderWeightDisplay,
          riderHeightDisplay,
          oxygenRequired,
          contactPrecautionsRequired,
          estimatedDurationMinutes,
          estimatedDistanceMiles,
          requestedStartTime,
          requestedEndTime,
          startLocationLatitude,
          startLocationLongitude,
          endLocationLatitude,
          endLocationLongitude,
          status,
          firstAvailable,
          riderWillUseProvidersWheelchair,
          payerName,
          payerType,
          startLocationRoomNumber,
          endLocationRoomNumber,
          bedsideNurseFullName,
          bedsideNursePhone,
          isLongDistance,
          riderDob,
          carePartnerDisplayName,
          carePartnerPhone,
          carePartnerRelationshipType,
          carePartnerRelationshipNotes,
          appointmentTime,
          transportReasonName,
          transportReasonCategoryName,
          transportReasonComment,
          biddingWindowEndsAt,
        }) => {
          return (
            <React.Fragment key={id}>
              {id == rowId ? (
                <>
                  <div style={mainDivStyles}>
                    <DashTimeFormat
                      requestedStartTime={requestedStartTime}
                      requestedEndTime={requestedEndTime}
                    />
                    <Box sx={{ display: "inline-flex", gap: "10px" }}>
                      {biddingWindowEndsAt ? (
                        <div className="display-button-flex">
                          <SecondaryButton
                            variant="contained"
                            onClick={() =>
                              handleFetchBestTimeOptions(
                                transportTypeId,
                                partnerId,
                                id
                              )
                            }
                            value={id}
                          >
                            Best Time
                          </SecondaryButton>
                        </div>
                      ) : null}
                      <div className="display-button-flex">
                        <PurpleButton
                          variant="contained"
                          onClick={(event) =>
                            handleFetchDriverData(
                              event,
                              transportTypeId,
                              partnerId
                            )
                          }
                          value={id}
                        >
                          Accept
                        </PurpleButton>
                      </div>
                    </Box>
                  </div>
                  {toggleBestTimeModal ? (
                    <BestTimeBidModal
                      rideId={id}
                      biddingWindowEndsAt={biddingWindowEndsAt}
                      setSubmitBidMessage={setSubmitBidMessage}
                      setSelectedBestTime={setSelectedBestTime}
                      selectedBestTime={selectedBestTime}
                      bestTimeOptions={bestTimeOptions}
                      driverId={driver}
                      partnerId={partnerId}
                      transportPartnerData={transportPartnerData}
                      transportTypeId={transportTypeId}
                      setDriver={setDriver}
                      setDriverFirstName={setDriverFirstName}
                      setDriverLastName={setDriverLastName}
                      driverData={driverData}
                      setDisable={setDisable}
                      disable={disable}
                      setPartnerId={setPartnerId}
                      closeButton={closeBidModal}
                      error={error}
                      setError={setError}
                      fetchBestTimeBids={fetchBestTimeBids}
                    />
                  ) : null}
                  {toggleClaimId == id ? (
                    <div style={{ width: "218px", position: "absolute" }}>
                      <div
                        style={{
                          zIndex: 999,
                          top: "-150px",
                          position: "absolute",
                        }}
                      >
                        <ClaimRideCard
                          isBariatric={isBariatric}
                          hasStairs={hasStairs}
                          transportTypeId={transportTypeId}
                          partnerId={partnerId}
                          setPartnerId={setPartnerId}
                          setDisable={setDisable}
                          disable={disable}
                          setReloadData={setReloadData}
                          setClaimRideMessage={setClaimRideMessage}
                          transportPartnerData={transportPartnerData}
                          driverData={driverData}
                          closeButton={cleanDriverFieldStates}
                          setDriverNotes={setDriverNotes}
                          setOpen={setOpen}
                          driver={driver}
                          setDriver={setDriver}
                          setDriverData={setDriverData}
                          setDriverFirstName={setDriverFirstName}
                          setDriverLastName={setDriverLastName}
                          driverFirstName={driverFirstName}
                        />
                      </div>
                      {open ? (
                        <DialogMessage
                          setOpen={setOpen}
                          setPartnerId={setPartnerId}
                          savedPartnerId={savedPartnerId} // HACK
                          driverFirstName={driverFirstName}
                          driverLastName={driverLastName}
                          id={id}
                          driverNotes={driverNotes}
                          setClaimRideMessage={setClaimRideMessage}
                          setAlertColor={setAlertColor}
                          setReloadData={setReloadData}
                          setDriverFirstName={setDriverFirstName}
                          setDriverLastName={setDriverLastName}
                          driver={driver}
                          setDriver={setDriver}
                          setDisable={setDisable}
                          setDriverData={setDriverData}
                          open={open}
                          setToggleClaimId={setToggleClaimId}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  <SidePanelDetails
                    id={id}
                    transportTypeId={transportTypeId}
                    rideType={rideType}
                    riderDisplayName={riderDisplayName}
                    hasStairs={hasStairs}
                    isBariatric={isBariatric}
                    startLocationName={startLocationName}
                    startLocationAddress={startLocationAddress}
                    startLocationNotes={startLocationNotes}
                    endLocationNotes={endLocationNotes}
                    endLocationAddress={endLocationAddress}
                    startLocationLatitude={startLocationLatitude}
                    startLocationLongitude={startLocationLongitude}
                    Duration={Duration}
                    endLocationName={endLocationName}
                    estimatedDurationMinutes={estimatedDurationMinutes}
                    estimatedDistanceMiles={estimatedDistanceMiles}
                    driverDisplayName={null}
                    requestedStartTime={requestedStartTime}
                    requestedEndTime={requestedEndTime}
                    vehicleSummary={""}
                    estimatedPayout={estimatedPayout}
                    bonus={bonus}
                    riderWillUseProvidersWheelchair={
                      riderWillUseProvidersWheelchair
                    }
                    rideNotes={rideNotes}
                    estimatedTotal={estimatedTotal}
                    riderWeightDisplay={riderWeightDisplay}
                    riderHeightDisplay={riderHeightDisplay}
                    oxygenRequired={oxygenRequired}
                    contactPrecautionsRequired={contactPrecautionsRequired}
                    riderNotes={riderNotes}
                    endLocationLatitude={endLocationLatitude}
                    endLocationLongitude={endLocationLongitude}
                    status={status}
                    firstAvailable={firstAvailable}
                    driverId={driverId}
                    driverName={null}
                    custodianPhone={custodianPhone}
                    custodianEmail={custodianEmail}
                    driverEmail={driverEmail}
                    driverPhone={driverPhone}
                    riderEmail={riderEmail}
                    riderPhone={riderPhone}
                    payerName={payerName}
                    payerType={payerType}
                    custodianDisplayName={custodianDisplayName}
                    startLocationRoomNumber={startLocationRoomNumber}
                    endLocationRoomNumber={endLocationRoomNumber}
                    bedsideNurseFullName={bedsideNurseFullName}
                    bedsideNursePhone={bedsideNursePhone}
                    isLongDistance={isLongDistance}
                    riderDob={riderDob}
                    carePartnerDisplayName={carePartnerDisplayName}
                    carePartnerPhone={carePartnerPhone}
                    carePartnerRelationshipType={carePartnerRelationshipType}
                    carePartnerRelationshipNotes={carePartnerRelationshipNotes}
                    appointmentTime={appointmentTime}
                    transportReasonName={transportReasonName}
                    transportReasonCategoryName={transportReasonCategoryName}
                    transportReasonComment={transportReasonComment}
                    biddingWindowEndsAt={biddingWindowEndsAt}
                    fetchBestTimeBids={fetchBestTimeBids}
                    bestTimeBids={bestTimeBids}
                  />
                </>
              ) : null}
            </React.Fragment>
          );
        }
      )}
    </div>
  );
};

export default PendingViewSidePanel;
